<template>
    <div id="app" class="h-100 d-flex flex-column">
        <div v-if="showZoneSelector">
            <ZoneSelectorModal :zones="myZones" @zone-selected="onZoneSelected" />
        </div>
        <!-- Surgeon / Customer Selector -->
        <div v-if="showSurgeonCustomerSelector">
            <SurgeonCustomerSelectorModal
                :zone="currentZone"
                @cancel="onCustomerSelectionCancel"
                @customer-selected="onCustomerSelected"
            />
        </div>
        <div v-if="showAcknowledgementModal">
            <AcknowledgementModal @acknowledgement-made="onAcknowledgementMade" />
        </div>
        <div v-if="showGdprModal">
            <GdprModal
                :show-gdpr-modal.sync="showGdprModal"
                :gdpr-message-count="currentUser.gdprMessageCount"
                @on-accepted="onGdprAccepted"
                @on-denied="signOutClicked"
            />
        </div>
        <!-- The universal header navbar. -->
        <b-navbar
            id="mainNavbar"
            v-if="!$route.meta.hideNavbar"
            toggleable="md"
            type="light"
            sticky
        >
            <b-navbar-brand to="/" class="d-flex align-items-center ml-2">
                <img src="./assets/stella-logo.png" width="30" :alt="t('orionLogoAlt')" />
            </b-navbar-brand>

            <!--<button @click="decrementRootFontSize">-</button>
            <div class="mx-2">ROOT FONT SIZE</div>
            <div class="mr-2">{{ rootFontSize }}</div>
            <button @click="incrementRootFontSize">+</button>-->

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <!--Mobile display only -->
                    <b-nav-text class="align-self-md-center text-center d-md-none">
                        <b-img
                            rounded="circle"
                            height="32"
                            :src="userThumbnailUrl"
                            :alt="t('userAlt')"
                        ></b-img>
                    </b-nav-text>
                    <b-nav-text class="align-self-md-center d-md-none">
                        <b-button
                            block
                            variant="white"
                            class="btn-surgeon-customer-selector"
                            @click="onCustomerButtonClick"
                        >
                            <span class="text-gray-darker" v-if="activeCustomer">{{
                                activeCustomer.name
                            }}</span>
                            <span class="text-gray-dark" v-if="activeSurgeon">
                                - {{ activeSurgeon.fullName }}</span
                            >
                            <b-icon icon="chevron-down" class="text-gray-darker ml-3"></b-icon>
                        </b-button>
                    </b-nav-text>
                    <!--Mobile display only -->
                    <b-nav-item to="/"
                        ><b-icon
                            icon="grid-1x2-fill"
                            class="pointer text-gray-dark mx-2 d-md-none"
                        />{{ t('dashboard') }}</b-nav-item
                    >
                    <b-nav-item
                        :to="{name: 'PatientList'}"
                        :active="$route.name === 'PatientList'"
                        :disabled="$route.name === 'PatientList'"
                    >
                        <b-icon icon="people-fill" class="pointer text-gray-dark mx-2 d-md-none" />
                        {{ t('patients') }}</b-nav-item
                    >
                    <b-nav-item
                        v-if="hasOrderPermission && !isDistributorSurgeonMode"
                        :to="{name: 'OrderList'}"
                        :active="$route.name === 'OrderList'"
                        :disabled="$route.name === 'OrderList'"
                        ><b-icon icon="receipt" class="pointer text-gray-dark mx-2 d-md-none" />
                        {{ t('orders') }}
                    </b-nav-item>
                    <b-nav-item
                        v-if="isDistributorSurgeonMode"
                        :to="{name: 'PreOrderList'}"
                        :active="$route.name === 'PreOrderList'"
                        :disabled="$route.name === 'PreOrderList'"
                        ><b-icon icon="receipt" class="pointer text-gray-dark mx-2 d-md-none" />{{
                            t('preorders')
                        }}</b-nav-item
                    >
                    <b-nav-item
                        :to="{name: 'ReserveList'}"
                        :active="$route.name === 'ReserveList'"
                        :disabled="$route.name === 'ReserveList'"
                        ><b-icon icon="receipt" class="pointer text-gray-dark mx-2 d-md-none" />{{
                            t('reserve')
                        }}</b-nav-item
                    >
                    <b-nav-item
                        v-if="hasSurgeonsPermission"
                        :to="{name: 'SurgeonList'}"
                        :active="$route.name === 'SurgeonList'"
                        :disabled="$route.name === 'SurgeonList'"
                    >
                        <b-icon
                            icon="person-lines-fill"
                            class="pointer text-gray-dark mx-2 d-md-none"
                        />{{ t('surgeons') }}</b-nav-item
                    >
                    <b-nav-item
                        :to="{name: 'InventoryList'}"
                        v-if="hasWarehouse"
                        :active="$route.name === 'InventoryList'"
                        :disabled="$route.name === 'InventoryList'"
                    >
                        <b-icon
                            icon="person-lines-fill"
                            class="pointer text-gray-dark mx-2 d-md-none"
                        />{{ t('inventory') }}
                    </b-nav-item>
                    <!--Desktop display only -->
                    <!--  Commented because of https://gitlab.artlogic.com/StaarSurgical/Orion/-/issues/1368 <b-nav-text class="mx-2 d-none d-md-block">|</b-nav-text> -->
                    <!--Desktop display only -->
                    <!--Mobile display only -->
                    <b-nav-text class="border-bottom pt-1 mb-2 d-md-none"></b-nav-text>
                    <!--Mobile display only -->
                    <b-nav-item
                        v-if="showAdminMenu"
                        :to="{name: hasUsersPermission ? 'UserList' : 'CustomerList'}"
                        :active="['UserList', 'CustomerList'].includes($route.name)"
                        :disabled="['UserList', 'CustomerList'].includes($route.name)"
                        ><b-icon icon="key-fill" class="pointer text-gray-dark mx-2 d-md-none" />
                        {{ t('admin') }}
                    </b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <!-- UAC disabled as per #2171
                    <b-nav-item to="/UAC" class="align-self-md-center" v-if="canViewLog"
                        ><b-icon icon="card-text" class="pointer text-gray-dark mx-2 d-md-none" />
                        {{ t('uac') }}</b-nav-item
                    >-->
                    <b-nav-item
                        @click="printReport"
                        class="pointer align-self-md-center"
                        v-if="$route.name == 'PreOpData'"
                    >
                        <b-button id="printReport" :disabled="isPrintDisabled">
                            {{ t('preOpPrintReport') }}
                            <b-icon icon="printer-fill" class="ml-1"></b-icon>
                        </b-button>
                    </b-nav-item>
                    <b-nav-item
                        key="shoppingcart"
                        to="/shoppingcart"
                        v-if="showShoppingCartButton"
                        class="align-self-md-center"
                    >
                        <b-button variant="success">
                            {{ t('checkout') }} - {{ shoppingCartItemsCount }}
                            <b-icon icon="cart" class="ml-1" />
                        </b-button>
                    </b-nav-item>
                    <!-- this has been removed
                        b-nav-item class="align-self-md-center">
                        <b-icon
                            class="pointer text-gray-dark mx-2"
                            icon="gear-fill"
                            @click="notImplemented"
                        />
                        <span class="d-md-none">{{ t('settings') }}</span></b-nav-item
                    -->

                    <b-nav-item
                        key="help"
                        class="align-self-md-center"
                        v-if="showHelp"
                        @click="goToHelp"
                        ><b-icon
                            width="2em"
                            height="2em"
                            class="pointer text-gray-dark mx-2"
                            icon="question-circle-fill"
                        />
                        <span class="d-md-none">{{ t('help') }}</span>
                    </b-nav-item>
                    <!--Desktop display only -->
                    <b-nav-text class="align-self-md-center d-none d-md-block">
                        <b-button
                            variant="white"
                            class="btn-surgeon-customer-selector ml-2"
                            v-b-tooltip.hover.html
                            :disabled="!get(myCustomers, 'length')"
                            :title="customerSelectorTooltip"
                            @click="onCustomerButtonClick"
                        >
                            <span class="text-gray-darker" v-if="activeCustomer">{{
                                activeCustomer.name
                            }}</span>
                            <span class="text-gray-dark" v-if="activeSurgeon">
                                - {{ activeSurgeon.fullName }}</span
                            >
                            <b-icon icon="chevron-down" class="text-gray-darker ml-3"></b-icon>
                        </b-button>
                    </b-nav-text>
                    <b-nav-item-dropdown
                        right
                        no-caret
                        class="align-self-md-center d-none d-md-block"
                    >
                        <template #button-content>
                            <b-img
                                rounded="circle"
                                height="32"
                                :src="userThumbnailUrl"
                                :alt="t('userAlt')"
                            ></b-img>
                        </template>
                        <template v-if="currentUser">
                            <b-dropdown-item :disabled="true">
                                <b>Email:</b> {{ get(currentUser, 'email') }}</b-dropdown-item
                            >
                            <b-dropdown-item :disabled="true">
                                <b>Role:</b> {{ get(currentUser, 'role.name') }}</b-dropdown-item
                            >
                        </template>
                        <b-dropdown-item @click="signOutClicked">
                            {{ t('signOut') }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <!--Mobile display only -->
                    <b-nav-text class="border-bottom pt-1 mb-2 d-md-none"></b-nav-text>
                    <b-nav-item class="d-md-none" @click="signOutClicked"
                        ><b-icon icon="x-circle-fill" class="pointer text-gray-dark mx-2" />
                        {{ t('signOut') }}
                    </b-nav-item>
                    <!--Mobile display only -->
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <SpinnerOverlay v-show="showSpinner" />
        <WarningModal ident="global" />

        <!-- Blur overlay -->
        <b-overlay :show="showBlurOverlay" variant="transparent" opacity="1" blur="10px">
            <router-view :key="$route.path" class="flex-grow" />
            <!-- Remove the spinner in the overlay -->
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import {SurgeonCustomerSelectorLaunchSources as LaunchSources} from '@/constants/customer.js';
import {ZoneCodes, ZoneCodeLocalStorageKey} from '@/constants/zone';
import {initAppData} from '@/utilities/app';
import {getCheckoutRouteNames} from '@/utilities/router';
import SurgeonCustomerSelectorModal from '@/components/SurgeonCustomerSelectorModal';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay';
import ZoneSelectorModal from './components/ZoneSelectorModal';
import AcknowledgementModal from './components/AcknowledgementModal';
import GdprModal from './components/GdprModal';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import i18n from '@/plugins/i18n';
import {HELP_ORIGIN} from '@/config';
/**
 * The initialization states. They are presented in the following order, as
 * necessary:
 *  - Zone Selector
 *  - GDPR
 *  - Customer Surgeon Selector
 *  - Acknowledgement
 */
const InitStates = Object.freeze({
    ZONE_SELECTOR: 'ZONE_SELECTOR',
    GDPR: 'GDPR',
    CUSTOMER_SURGEON_SELECTOR: 'CUSTOMER_SURGEON_SELECTOR',
    ACKNOWLEDGEMENT: 'ACKNOWLEDGEMENT',
});

export default {
    name: 'App',
    components: {
        SpinnerOverlay,
        SurgeonCustomerSelectorModal,
        ZoneSelectorModal,
        AcknowledgementModal,
        GdprModal,
    },
    data() {
        return {
            InitStates,
            info: null,
            currentInitState: null,
            /* Data items related to zone selection. */
            ZoneCodes,
            ZoneCodeLocalStorageKey,
            showZoneSelector: false,
            /* Data items related to customer/surgeon selection. */
            LaunchSources,
            showSurgeonCustomerSelector: false,
            surgeonCustomerSelectorLaunchSource: LaunchSources.LOGIN,
            customer: null,
            surgeon: null,
            rootFontSize: null,
            /* Data items related to the acknowledgement message. */
            showAcknowledgementModal: false,
            /* Data items related to the gdpr message. */
            showGdprModal: false,
        };
    },

    async mounted() {
        //this.rootFontSize = 14;
        //document.documentElement.style.fontSize = `${this.rootFontSize}` + 'px';

        if (!this.currentZone) {
            await this.processZone();
        } else {
            await this.processActiveDefaultSurgeonCustomer();
        }

        if (isEmpty(this.currentUser)) {
            return;
        }

        this.currentInitState = this.InitStates.ZONE_SELECTOR;
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            currentZone: 'zone/currentZone',
            myZones: 'user/myZones',
            permissions: 'permissions/permissions',
            defaultCustomerId: 'user/defaultCustomerId',
            defaultDoctorId: 'user/defaultDoctorId',
            activeCustomerId: 'user/activeCustomerId',
            activeDoctorId: 'user/activeDoctorId',
            userThumbnailUrl: 'user/userThumbnailUrl',
            myCustomers: 'user/myCustomers',
            mySurgeons: 'user/myDoctors',
            canViewLog: 'logging/canViewLog',
            isPrintDisabled: 'preopdata/isPrintDisabled',
            myAcknowledgement: 'user/myAcknowledgement',
        }),
        ...mapState({
            showSpinner: (state) => state.app.showSpinner,
            shoppingCartItemsCount: (state) => state.cart.itemsCount,
        }),
        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },
        hasOrderPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERS]: [PERMISSIONS_VALUES.READ_ONLY, PERMISSIONS_VALUES.READ_WRITE],
            });
        },
        hasSurgeonsPermission() {
            return this.checkPermissions({
                [PERMISSIONS.SURGEONS]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        showAdminMenu() {
            return this.hasUsersPermission || this.hasCustomersPermission;
        },
        hasUsersPermission() {
            return this.checkPermissions({
                [PERMISSIONS.USERS]: PERMISSIONS_VALUES.READ_TYPES,
            });
        },
        hasCustomersPermission() {
            return this.checkPermissions({
                [PERMISSIONS.CUSTOMER_ADMIN]: PERMISSIONS_VALUES.WRITE_TYPES,
            });
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        activeCustomer: function () {
            return this.getCustomer(this.activeCustomerId);
        },
        activeSurgeon: function () {
            return this.getSurgeon(this.activeDoctorId);
        },
        showBlurOverlay: function () {
            return (
                this.showZoneSelector ||
                this.showSurgeonCustomerSelector ||
                this.showAcknowledgementModal ||
                this.showGdprModal
            );
        },
        showShoppingCartButton: function () {
            return (
                !['ShoppingCart', ...getCheckoutRouteNames()].includes(this.$route.name) &&
                this.hasOrderingPermission
            );
        },
        hasWarehouse() {
            //set hasWarehouse if the activeCustomerWarehouseNumber is not null
            return get(this.currentUser, 'activeCustomerWarehouseNumber') != null;
        },
        showHelp() {
            return has(this.$route, ['meta', 'help']);
        },
        customerSelectorTooltip() {
            let customerSurgeonInfo = `${this.t('customer')}: ${
                this.currentUser?.activeOcosCustomerId
            }<br/>`;
            if (this.activeSurgeon) {
                customerSurgeonInfo += `${this.t('surgeon')}: ${this.activeSurgeon.ocosDoctorId}`;
            } else {
                customerSurgeonInfo += `${this.t('surgeon')}: ${this.t('all').toUpperCase()}`;
            }
            return customerSurgeonInfo + '<br/><br/>' + this.t('customerSelectorTooltip');
        },
    },

    watch: {
        async currentInitState(modal) {
            switch (modal) {
                case InitStates.ZONE_SELECTOR:
                    await this.processZone();
                    break;
                case InitStates.GDPR:
                    await this.processGdpr();
                    break;
                case InitStates.CUSTOMER_SURGEON_SELECTOR:
                    await this.processActiveDefaultSurgeonCustomer();
                    break;
                case InitStates.ACKNOWLEDGEMENT:
                    await this.processAcknowledgement();
                    break;
                default:
                    break;
            }
        },
    },

    methods: {
        get,
        ...mapActions('user', [
            'fetchMyZones',
            'fetchMyCustomers',
            'fetchMyDoctors',
            'setActiveCustomerId',
            'setActiveDoctorId',
            'setDefaultCustomerId',
            'setGdprAcceptance',
        ]),
        ...mapActions('cart', ['fetchItemsCount']),
        ...mapMutations('zone', ['setCurrentZone']),
        ...mapMutations('user', ['setMyAcknowledgement']),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser?.accessPermissions
            );
        },
        goToHelp() {
            const helpRoute = [
                HELP_ORIGIN,
                this.currentUser.language,
                this.currentZone,
                get(this.$route, 'meta.help'),
            ];
            const windowWidth = window.screen.width - window.screen.width * 0.3;
            const windowHeight = window.screen.height - window.screen.height * 0.3;
            window.open(
                helpRoute.join('/').toLowerCase(),
                'newwindow',
                `width=${windowWidth},
                height=${windowHeight}
                left=${(window.screen.width - windowWidth) / 2},
                top=${(window.screen.height - windowHeight) / 2}`
            );
        },
        async languageChanged(languageKey) {
            await i18n.i18next.changeLanguage(languageKey);
        },
        /**
         * Process the zone
         *
         * If a zone has been set, go to the next initialization state. If not,
         * proceed with setting the zone: If the current user's zones include
         * all the zones in the app, allow the user to select which one to use.
         * Otherwise, look for each valid zone and use the first one found.
         */
        async processZone() {
            if (!this.currentZone) {
                await this.fetchMyZones();

                const zoneCodes = Object.keys(ZoneCodes);
                const myZoneCodes = this.myZones.map((z) => z.code);

                if (zoneCodes.every((z) => myZoneCodes.includes(z))) {
                    this.showZoneSelector = true;
                } else if (myZoneCodes.includes(ZoneCodes.OUS)) {
                    let zone = this.myZones.find((z) => z.code === ZoneCodes.OUS);
                    await this.onZoneSelected(zone);
                } else if (myZoneCodes.includes(ZoneCodes.US)) {
                    let zone = this.myZones.find((z) => z.code === ZoneCodes.US);
                    await this.onZoneSelected(zone);
                } else {
                    alert('No valid zone found for this user');
                }
            } else {
                this.currentInitState = this.InitStates.GDPR;
            }
        },

        /**
         * Process the display of GDPR message the user must accept before
         * continuing to the app. The message will only appear if the user has
         * not yet accepted it in the selected zone
         */
        async processGdpr() {
            // OUS zone need approval for two messages, we need two ensure both messages were already shown
            const messagesPerZone = {[ZoneCodes.US]: 0, [ZoneCodes.OUS]: 1};
            if (this.currentUser.gdprMessageCount > messagesPerZone[this.currentZone]) {
                this.currentInitState = this.InitStates.CUSTOMER_SURGEON_SELECTOR;
            } else {
                this.showGdprModal = true;
            }
        },

        /**
         * Process the active and default surgeon and customer
         *
         * First, if there is a default customer set but it is not in the
         * user's list of customers unset the default customer.
         *
         * Next, if the user has no customers then show an alert. If there is
         * a default selection, only one customer, or an existing active
         * customer ID, then use it. Otherwise, open the Surgeon/Customer
         * selector to allow the user to select a customer and optionally
         * a surgeon.
         */
        async processActiveDefaultSurgeonCustomer() {
            if (this.defaultCustomerId && !this.getCustomer(this.defaultCustomerId)) {
                await this.setDefaultCustomerId(null);
            }

            if (this.myCustomers.length === 0) {
                alert('No customers found for this user');
            } else if (this.defaultCustomerId) {
                // fetchMyCustomers and fetchMyDocters require
                // setActiveCustomerId to be set
                await this.setActiveCustomerId(this.defaultCustomerId);
                await this.blockUntilAllSettled([
                    this.fetchMyCustomers(),
                    this.fetchMyDoctors(),
                    this.fetchItemsCount(),
                ]);

                if (this.defaultDoctorId) {
                    await this.setActiveDoctorId(this.defaultDoctorId);
                }

                this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;
            } else if (this.myCustomers.length === 1) {
                // fetchMyCustomers and fetchMyDocters require
                // setActiveCustomerId to be set
                await this.setActiveCustomerId(this.myCustomers[0]?.customerId);
                await this.blockUntilAllSettled([
                    this.fetchMyCustomers(),
                    this.fetchMyDoctors(),
                    this.fetchItemsCount(),
                ]);

                if (this.activeDoctorId) {
                    await this.blockUntilAllSettled([this.fetchMyDoctors()]);
                }

                if (this.mySurgeons.length === 1) {
                    await this.setActiveDoctorId(this.mySurgeons[0]?.doctorId);
                }

                this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;
            } else if (this.activeCustomerId) {
                await this.blockUntilAllSettled([this.fetchMyCustomers(), this.fetchItemsCount()]);

                if (this.activeDoctorId) {
                    await this.blockUntilAllSettled([this.fetchMyDoctors()]);
                }

                this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;
            } else {
                this.showSurgeonCustomerSelector = true;
            }
        },

        /**
         * Process the display of the message the user must acknowledge before
         * continuing to the app. The message will only appear if the user has
         * not yet acknowledged the message during the current logged-in session.
         */
        async processAcknowledgement() {
            if (this.myAcknowledgement !== 'true') {
                this.showAcknowledgementModal = true;
            }
        },

        /**
         * Handle when a zone is selected
         *
         * @param {Object} selectedZone
         */
        async onZoneSelected(selectedZone) {
            this.setCurrentZone(selectedZone.code);

            // This will refetch the initial app data so that it corresponds
            // to the current zone
            await initAppData();
            await this.languageChanged(this.currentUser.language);

            if (this.activeCustomerId) {
                await this.setActiveCustomerId(null);
            }

            if (this.activeDoctorId) {
                await this.setActiveDoctorId(null);
            }

            this.currentInitState = this.InitStates.GDPR;
            this.showZoneSelector = false;
        },

        /**
         * Show the Surgeon Customer Selector modal when the customer button
         * is clicked
         */
        onCustomerButtonClick: function () {
            this.showSurgeonCustomerSelector = true;
            this.surgeonCustomerSelectorLaunchSource = LaunchSources.DASHBOARD;
        },

        /**
         * Handle the customer selection. This may also include a surgeon
         * selection.
         */
        onCustomerSelected: async function ({selectedCustomer, selectedSurgeon}) {
            await this.blockingRequest('patientList/clearPatientList');
            await this.blockingRequest('userList/clearList');

            this.customer = selectedCustomer;
            this.surgeon = selectedSurgeon;
            await this.setActiveCustomerId(this.customer?.customerId);
            await this.setActiveDoctorId(this.surgeon?.doctorId);
            await this.fetchItemsCount();

            this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;

            this.showSurgeonCustomerSelector = false;
        },

        /**
         * Handle the customer/surgeon selection cancellation depending on
         * where the modal was launched from
         */
        onCustomerSelectionCancel: function () {
            if (this.surgeonCustomerSelectorLaunchSource === LaunchSources.LOGIN) {
                this.signOutClicked();
                this.showSurgeonCustomerSelector = false;
            } else if (this.surgeonCustomerSelectorLaunchSource === LaunchSources.DASHBOARD) {
                this.showSurgeonCustomerSelector = false;
            }
        },

        /**
         * Handle when the acknowledgment is made
         */
        onAcknowledgementMade: function () {
            this.setMyAcknowledgement('true');
            this.showAcknowledgementModal = false;
        },

        /**
         * Handle when the GDPR is accepted
         */
        onGdprAccepted: async function (isCompleted) {
            this.setSpinner(true);
            const success = await this.setGdprAcceptance();
            if (success && isCompleted) {
                this.currentInitState = this.InitStates.CUSTOMER_SURGEON_SELECTOR;
            }
            this.setSpinner(false);
        },

        /**
         * Get the customer associated with the given customer ID from the current
         * list of customers.
         *
         * @param {Number} customerId
         * @returns the customer if found, otherwise null
         */
        getCustomer: function (customerId) {
            if (!this.myCustomers || !customerId) {
                return null;
            } else {
                return this.myCustomers.find((c) => {
                    return c.customerId === customerId;
                });
            }
        },

        /**
         * Get the surgeon associated with the given doctor ID from the current
         * list of surgeons.
         *
         * @param {Number} doctorId
         * @returns the surgeon if found, otherwise null
         */
        getSurgeon: function (doctorId) {
            if (!this.mySurgeons || !doctorId) {
                return null;
            } else {
                return this.mySurgeons.find((d) => {
                    return d.doctorId === doctorId;
                });
            }
        },

        /**
         * Handle the user signing out. This will clear some data and then log
         * the user out of Keycloak. Once the user is logged out of Keycloak
         * there may be additional clean up e.g. removing the zone-code from
         * local storage.
         */
        signOutClicked: async function () {
            if (this.currentUser) {
                await this.setActiveCustomerId(null);
                await this.setActiveDoctorId(null);
            }
            this.$router.push({name: 'LoggingOut'});
        },

        printReport() {
            const routeData = this.$router.resolve({
                name: 'PreOpReport',
                params: {preOpDataSetId: this.$route.params.preOpDataSetId},
            });

            window.open(routeData.href, '_blank');
        },

        incrementRootFontSize: function () {
            this.rootFontSize++;
            document.documentElement.style.fontSize = `${this.rootFontSize}` + 'px';
        },
        decrementRootFontSize: function () {
            this.rootFontSize--;
            document.documentElement.style.fontSize = `${this.rootFontSize}` + 'px';
        },
    },
};
</script>
<style lang="scss" scoped>
#printReport {
    color: white;
    background-color: #616469;
}

button#printReport[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>

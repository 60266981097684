import store from '@/store/index';

/**
 * Initialize the app data
 *
 * This is usually called after a login, page refresh, or zone selection
 *
 */
export async function initAppData() {
    await store.dispatch('user/fetchUser');
    await store.dispatch('user/fetchMyCustomers');
    await store.dispatch('user/fetchMyDoctors');
    await store.dispatch('user/fetchMyLanguages');
    await store.dispatch('permissions/fetchList');
    await store.dispatch('cart/fetchItemsCount');
}

// current user module
import mergeWith from 'lodash/mergeWith';
import {DEFAULT_DECIMAL_SEPATOR, DEFAULT_LANGUAGE_KEY} from '@/config';
import {AcknowledgementLocalStorageKey} from '@/constants/user';
import {ZoneCodes} from '@/constants/zone';

const state = () => ({
    currentUser: null,
    myZones: null,
    myCustomers: null,
    myDoctors: null,
    myLanguages: [],
    myAcknowledgement: localStorage.getItem(AcknowledgementLocalStorageKey),
});

const getters = {
    currentUser: ({currentUser}, _, __, rootGetters) => {
        const currentZone = rootGetters['zone/currentZone'];
        const enforceUserSettings = {language: currentUser.language ?? DEFAULT_LANGUAGE_KEY};
        // When US zone, users should use fixed settings per https://gitlab.artlogic.com/StaarSurgical/Orion/-/issues/2623
        if (!currentZone || currentZone == ZoneCodes.US) {
            enforceUserSettings.decimalSeparator = DEFAULT_DECIMAL_SEPATOR;
            enforceUserSettings.language = DEFAULT_LANGUAGE_KEY;
        }
        const user = currentUser
            ? mergeWith(
                  {decimalSeparator: DEFAULT_DECIMAL_SEPATOR},
                  currentUser,
                  enforceUserSettings,
                  (a, b) => (b === null ? a : undefined)
              )
            : currentUser;
        return user;
    },
    /**
     * The current user's available zones
     *
     * @param {Object} state
     * @returns the zones
     */
    myZones: (state) => state.myZones,
    /**
     * The current user's customers
     *
     * @param {Object} state
     * @returns the customers
     */
    myCustomers: (state) => state.myCustomers,
    /**
     * The current user's doctors
     *
     * @param {Object} state
     * @returns the doctors
     */
    myDoctors: (state) => state.myDoctors,
    /**
     * The current user's languages
     *
     * @param {Object} state
     * @returns the languages
     */
    myLanguages: (state) =>
        state.myLanguages.map((l) => ({...l, name: `${l.name} (${l.code.toUpperCase()})`})),
    /**
     * The current active customer object
     *
     * @param {Object} state
     * @returns the current active customer
     */
    activeCustomer: (state) => {
        return state.myCustomers.find((c) => c.customerId === state.currentUser?.activeCustomerId);
    },
    /**
     * @deprecated Should not be on the user model. Should be stored in local
     *   storage. Will be removed from the user model for the multiple tabs
     *   feature.
     */
    activeCustomerId: (state) => state.currentUser?.activeCustomerId,
    /**
     * @deprecated Should not be on the user model. Should be stored in local
     *   storage. Will be removed from the user model for the multiple tabs
     *   feature.
     */
    activeDoctorId: (state) => state.currentUser?.activeDoctorId,
    /**
     * The default customer id as selected by the user in the
     * Surgeon/Customer Selector modal
     *
     * @param {Object} state
     * @returns the customer id
     */
    defaultCustomerId: (state) => state.currentUser?.defaultCustomerId,
    /**
     * The default doctor id as selected by the user in the
     * Surgeon/Customer Selector modal
     *
     * @param {Object} state
     * @returns the doctor id
     */
    defaultDoctorId: (state) => state.currentUser?.defaultDoctorId,
    userThumbnailUrl: (state) => {
        if (state.currentUser && state.currentUser.thumbnailUrl) {
            return state.currentUser.thumbnailUrl;
        }
        //return the blank user image if no URL
        return require('../../assets/blankuser.png');
    },
    /**
     * Indicates whether or not the current user has acknowledged the message
     * presented during login
     *
     * @param {Object} state
     * @returns the acknowledgement status
     */
    myAcknowledgement: (state) => state.myAcknowledgement,
};

const mutations = {
    setProfile(state, currentUser) {
        state.currentUser = currentUser;
    },
    setMyZones(state, myZones) {
        state.myZones = myZones;
    },
    setMyCustomers(state, myCustomers) {
        state.myCustomers = myCustomers;
    },
    setMyDoctors(state, myDoctors) {
        state.myDoctors = myDoctors;
    },
    setMyLanguages(state, myLanguages) {
        state.myLanguages = myLanguages;
    },
    setActiveCustomerId(state, activeCustomerId) {
        state.currentUser['activeCustomerId'] = activeCustomerId;
    },
    setActiveDoctorId(state, activeDoctorId) {
        state.currentUser['activeDoctorId'] = activeDoctorId;
    },
    setDefaultCustomerId(state, defaultCustomerId) {
        state.currentUser['defaultCustomerId'] = defaultCustomerId;
    },
    setDefaultDoctorId(state, defaultDoctorId) {
        state.currentUser['defaultDoctorId'] = defaultDoctorId;
    },
    setGdprAcceptance(state, {gdprAcceptanceDate, gdprMessageCount}) {
        state.currentUser['gdprAcceptanceDate'] = gdprAcceptanceDate;
        state.currentUser['gdprMessageCount'] = gdprMessageCount;
    },
    setMyAcknowledgement(state, myAcknowledgement) {
        localStorage.setItem(AcknowledgementLocalStorageKey, myAcknowledgement);
        state.myAcknowledgement = myAcknowledgement;
    },
    removeMyAcknowledgement(state) {
        localStorage.removeItem(AcknowledgementLocalStorageKey);
        state.myAcknowledgement = null;
    },
};

const actions = {
    async fetchUser({commit}) {
        const user = (await this._vm.$http.get(`users/me`)).data;

        //append the zone info to the current user object
        const zoneInfo = (await this._vm.$http.get(`users/me/zones`)).data;
        user.zoneInfo = zoneInfo;

        commit('setProfile', user);
    },

    /* Fetch the current user's available zones */
    async fetchMyZones({commit}) {
        let urlString = `users/me/zones`;
        try {
            let result = await this._vm.$http.get(urlString);
            let myZones = result.data;
            commit('setMyZones', myZones);
        } catch (err) {
            alert("Unable to fetch the current user's available zones.");
        }
    },

    /* Fetch the current user's customers */
    async fetchMyCustomers({commit}, params = {}) {
        let {query, doctorId, setMyCustomers = true} = params;
        let urlString = `users/me/customers`;

        if (query || doctorId) {
            urlString += '?';

            if (doctorId) {
                urlString += `doctorId=${doctorId}`;

                if (query) {
                    urlString += `&query=${encodeURIComponent(query)}`;
                }
            } else if (query) {
                urlString += `query=${encodeURIComponent(query)}`;
            }
        }
        try {
            let result = await this._vm.$http.get(urlString);

            let myCustomers = result.data;

            if (setMyCustomers) {
                commit('setMyCustomers', myCustomers);
            }

            return myCustomers;
        } catch (err) {
            alert("Unable to fetch the current user's customers.");
        }
    },

    /* Fetch the current user's doctors */
    async fetchMyDoctors({commit}, params = {}) {
        let {query = null, setMyDoctors = true} = params;
        let urlString = `users/me/doctors`;

        if (query) {
            urlString += `?query=${encodeURIComponent(query)}`;
        }

        try {
            let result = await this._vm.$http.get(urlString);

            let myDoctors = result.data;

            if (setMyDoctors) {
                commit('setMyDoctors', myDoctors);
            }

            return myDoctors;
        } catch (err) {
            alert("Unable to fetch the current user's doctors.");
        }
    },

    /* Fetch the current user's doctors */
    async fetchMyLanguages({commit}) {
        const urlString = `users/languages`;
        try {
            const {data} = await this._vm.$http.get(urlString);
            const myLanguages = data.results;
            commit('setMyLanguages', myLanguages);
        } catch (err) {
            alert("Unable to fetch the current user's languages.");
        }
    },

    /* Store the active customer for the current user */
    async setActiveCustomerId({commit, getters, dispatch}, customerId) {
        let urlString = 'users/me/activeCustomer';
        try {
            await this._vm.$http.post(urlString, {
                customerId: customerId ? parseInt(customerId) : customerId,
            });
            commit('setActiveCustomerId', customerId);
            // some user info is based on the customer being viewed, so
            // re-fetch the user
            await dispatch('fetchUser');
        } catch (err) {
            alert('Unable to set active customer');
        }
    },

    /* Store the active doctor (surgeon) for the current user */
    async setActiveDoctorId({commit}, doctorId) {
        let urlString = 'users/me/activeDoctor';
        try {
            await this._vm.$http.post(urlString, {
                doctorId: doctorId ? parseInt(doctorId) : doctorId,
            });
            commit('setActiveDoctorId', doctorId);
        } catch (err) {
            alert('Unable to set active doctor');
        }
    },

    /* Store the default customer for the current user */
    async setDefaultCustomerId({commit}, customerId) {
        let urlString = 'users/me/defaultCustomer';
        try {
            await this._vm.$http.post(urlString, {
                customerId: customerId ? parseInt(customerId) : customerId,
            });
            commit('setDefaultCustomerId', customerId);
        } catch (err) {
            alert('Unable to set default customer');
        }
    },

    /* Store the default doctor (surgeon) for the current user */
    async setDefaultDoctorId({commit}, doctorId) {
        let urlString = 'users/me/defaultDoctor';
        try {
            await this._vm.$http.post(urlString, {
                doctorId: doctorId ? parseInt(doctorId) : doctorId,
            });
            commit('setDefaultDoctorId', doctorId);
        } catch (err) {
            alert('Unable to set default doctor');
        }
    },

    /* Store the default doctor (surgeon) for the current user */
    async setGdprAcceptance({commit}) {
        let urlString = 'users/me/gdpracceptance';
        try {
            const {data} = await this._vm.$http.post(urlString);
            commit('setGdprAcceptance', data.results);
            return data.success;
        } catch (err) {
            alert('Unable to set GDPR acceptance');
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

import Vue from 'vue';
import i18n from '@/plugins/i18n';

const defaultWarningConfig = () => ({
    text: null,
    title: i18n.i18next.t('warning'),
    ok: () => {},
    cancel: () => {},
    okTitle: i18n.i18next.t('ok'),
    cancelTitle: i18n.i18next.t('cancel'),
    position: null,
});

const state = () => ({
    warningConfig: defaultWarningConfig(),
});

const getters = {
    warningConfig: (state) => state.warningConfig,
};

const mutations = {
    showWarningModal(state, config) {
        Vue.set(state, 'warningConfig', Object.assign(defaultWarningConfig(), config));
        this.$app.$bvModal.show('modal-warning-global');
    },
    hideWarningModal() {
        this.$app.$bvModal.hide('modal-warning-global');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};

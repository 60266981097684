import {mapMutations} from 'vuex';

export default {
    data() {
        return {};
    },
    methods: {
        ...mapMutations({
            setSpinner: 'app/setSpinner',
        }),
        /* Call the Vuex method to fetch and commit the backend API info. */
        async fetchApiInfo() {
            await this.blockingRequest('apiversion/fetchApiVersionInfo');
        },

        /**
         * Dispatch an action, blocking user input with an overlay until the Promise is resolved
         * @param {string} action - A Vuex action
         * @param {Object} payload - payload object to dispatch the action with
         * @returns The return value from the action
         */
        async blockingRequest(action, payload) {
            let response;
            this.setSpinner(true);
            try {
                response = await this.$store.dispatch(action, payload);
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
                throw error;
            } finally {
                this.setSpinner(false);
            }
            return response;
        },

        /**
         * Block user input until all the promises are resolved
         * @param {Promise[]} promises - An array of promises
         */
        async blockUntilAllSettled(promises) {
            this.setSpinner(true);
            return Promise.allSettled(promises).finally(() => {
                this.setSpinner(false);
            });
        },
        async exportData(entity, params) {
            let response;
            this.setSpinner(true);
            try {
                response = await this.$http.get(`export/${entity}`, {params});
                const type = response.headers['content-type'] || '';
                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(new Blob([response.data], {type}));
                anchor.target = '_blank';
                anchor.download = `${entity}.csv`;
                anchor.click();
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
                throw error;
            } finally {
                this.setSpinner(false);
            }
            return response;
        },
        /* Triggers a standard alert message for features that are not yet implemented. */
        notImplemented: function () {
            this.$bvToast.toast(this.t('toastMessage_NotImplemented'), {
                title: this.t('toastTitle_NotImplemented'),
                variant: 'warning',
                solid: true,
            });
        },

        postLogMessage: async function (logLevel, message, parameters) {
            await this.$store.dispatch('logging/postLogMessage', {
                logLevel: logLevel,
                message: message,
                parameters: parameters,
            });
        },
    },

    watch: {
        currentLanguageCode: function (value) {
            localStorage.currentLanguageCode = value;
        },
    },
};

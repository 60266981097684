import Vue from 'vue';
import VueAxios from 'vue-plugin-axios';
import axios from 'axios';
import {ZoneCodeLocalStorageKey} from '@/constants/zone';

Vue.use(VueAxios, {
    axios,
    config: {
        baseURL:
            process.env.NODE_ENV === 'development'
                ? 'http://localhost:33145/api/v1'
                : process.env.VUE_APP_ORIONAPI_URL,
        headers: {
            'Content-Type': 'application/json',
            patch: {
                'Content-Type': 'application/json',
            },
            post: {
                'Content-Type': 'application/json',
            },
            put: {
                'Content-Type': 'application/json',
            },
        },
    },
    interceptors: {
        async beforeRequest(config) {
            // Add auth header if a token has been obtained
            const token = await Vue.updateToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            // Add zone header if the zone is available
            const zoneCode = localStorage.getItem(ZoneCodeLocalStorageKey);
            if (zoneCode) {
                config.headers['X-Orion-Zone'] = zoneCode;
            }

            if (config.url && config.url.includes && config.url.includes('v1.1')) {
                config.baseURL = config.baseURL.replace('/v1', '');
            }

            return config;
        },
    },
});

<template>
    <b-modal
        id="surgeon-customer-selector"
        centered
        no-fade
        hide-backdrop
        no-close-on-backdrop
        no-close-on-esc
        content-class="surgeon-customer-selector-content"
        header-class="surgeon-customer-selector-header"
        body-class="surgeon-customer-selector-body"
        footer-class="surgeon-customer-selector-footer"
        visible
        @cancel="onCancel"
    >
        <template #modal-header>
            <div>
                {{ headerText }}
            </div>
        </template>
        <template #default>
            <b-container>
                <!-- Selected Surgeon -->
                <b-row
                    v-if="
                        modalState.name === ModalStateNames.SURGEON_SELECTED ||
                        modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED
                    "
                    class="mb-3"
                >
                    <b-col>
                        <span>
                            <span class="text-gray-darker heavy">
                                {{ selectedSurgeon.lastName }}, {{ selectedSurgeon.firstName }}
                            </span>
                            <span class="text-small text-gray-dark">
                                <em>{{ selectedSurgeon.ocosDoctorId }}</em>
                            </span>
                        </span>
                    </b-col>
                </b-row>
                <!-- Selected Customer -->
                <b-row
                    v-if="
                        modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED ||
                        modalState.name === ModalStateNames.CUSTOMER_SELECTED
                    "
                >
                    <b-col>
                        <span>
                            <span class="text-gray-darker heavy">
                                {{ selectedCustomer.name }}
                            </span>
                            <span class="text-gray-darker" v-if="zone === ZoneCodes.OUS">
                                {{ selectedCustomer.city }}, {{ selectedCustomer.country }}
                            </span>
                            <span v-else-if="zone === ZoneCodes.US">
                                {{ selectedCustomer.city }}, {{ selectedCustomer.state }}
                            </span>
                            <span class="text-small text-gray-dark">
                                <em>{{ selectedCustomer.ocosCustomerNumber }}</em>
                            </span>
                        </span>
                    </b-col>
                </b-row>
                <!-- Search Bar -->
                <b-row
                    v-if="
                        modalState.name === ModalStateNames.BASE ||
                        modalState.name === ModalStateNames.VIEW_ALL_SURGEONS ||
                        modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS ||
                        modalState.name === ModalStateNames.SURGEON_SELECTED
                    "
                >
                    <b-col>
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input
                                trim
                                ref="searchInput"
                                type="search"
                                v-model="searchText"
                                :placeholder="searchPlaceholderText"
                            >
                            </b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-card class="mt-2">
                    <!-- Surgeons List -->
                    <div
                        v-if="
                            modalState.name === ModalStateNames.BASE ||
                            modalState.name === ModalStateNames.VIEW_ALL_SURGEONS
                        "
                        class="surgeons-list"
                    >
                        <b-row
                            v-if="modalState.name === ModalStateNames.BASE"
                            align-v="end"
                            class="surgeons-list-header pb-2 mb-1"
                        >
                            <b-col cols="9">
                                <span class="text-caption text-gray-dark">
                                    {{ t('surgeonCustomerSelector_SurgeonHeader') }}
                                </span>
                            </b-col>
                            <b-col cols="3" class="text-caption text-right">
                                <b-link href="#" @click="onViewAllSurgeons">
                                    {{ t('surgeonCustomerSelector_ViewAll') }}
                                </b-link>
                            </b-col>
                        </b-row>
                        <hr v-if="modalState.name === ModalStateNames.BASE" />
                        <div ref="surgeonsListContents" :class="surgeonsListClass">
                            <div v-if="!modalReady">
                                <SpinnerOverlay position="absolute"></SpinnerOverlay>
                            </div>
                            <b-row>
                                <b-col>
                                    <ul>
                                        <li
                                            v-for="surgeon in surgeonsProcessed"
                                            :key="surgeon.doctorId"
                                            class="pointer surgeons-list-contents-item"
                                            @click="onSurgeonSelected(surgeon)"
                                        >
                                            <div class="surgeons-list-contents-item-details">
                                                <SearchedText
                                                    :searchedItem="surgeon"
                                                    searchedProperty="lastName"
                                                ></SearchedText>
                                                <span>,</span>
                                                <SearchedText
                                                    class="ml-1"
                                                    :searchedItem="surgeon"
                                                    searchedProperty="firstName"
                                                ></SearchedText>
                                                <em>
                                                    <SearchedText
                                                        class="ml-1 text-small"
                                                        classBaseText="text-gray-dark"
                                                        :searchedItem="surgeon"
                                                        searchedProperty="ocosDoctorId"
                                                    ></SearchedText>
                                                </em>
                                            </div>
                                        </li>
                                        <li
                                            v-if="modalReady && surgeonsProcessed.length === 0"
                                            class="surgeons-list-contents-item"
                                        >
                                            <div class="surgeons-list-contents-item-details">
                                                <div>
                                                    {{
                                                        t('surgeonCustomerSelector_NoSurgeonFound')
                                                    }}
                                                    {{ searchText }}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <!-- Customers List -->
                    <div
                        v-if="
                            modalState.name === ModalStateNames.BASE ||
                            modalState.name === ModalStateNames.SURGEON_SELECTED ||
                            modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS
                        "
                        class="customers-list mt-2"
                    >
                        <b-row
                            v-if="modalState.name === ModalStateNames.BASE"
                            align-v="end"
                            class="customers-list-header pb-2 mb-1"
                        >
                            <b-col cols="9">
                                <span class="text-caption text-gray-dark">
                                    {{ t('surgeonCustomerSelector_Customers') }}
                                </span>
                            </b-col>
                            <b-col
                                v-if="modalState.name === ModalStateNames.BASE"
                                cols="3"
                                class="text-caption text-right"
                            >
                                <b-link href="#" @click="onViewAllCustomers">
                                    {{ t('surgeonCustomerSelector_ViewAll') }}
                                </b-link>
                            </b-col>
                        </b-row>
                        <hr v-if="modalState.name === ModalStateNames.BASE" />
                        <div ref="customersListContents" :class="customersListClass">
                            <b-row>
                                <b-col>
                                    <ul>
                                        <li
                                            v-for="customer in customersProcessed"
                                            :key="customer.customerId"
                                            class="pointer customers-list-contents-item"
                                            @click="onCustomerSelected(customer)"
                                        >
                                            <div class="customers-list-contents-item-details">
                                                <SearchedText
                                                    :searchedItem="customer"
                                                    searchedProperty="name"
                                                ></SearchedText>
                                                <div
                                                    v-if="zone === ZoneCodes.OUS"
                                                    class="ml-1 text-gray-dark"
                                                >
                                                    <span
                                                        >{{ customer.city }},
                                                        {{ customer.country }}</span
                                                    >
                                                </div>
                                                <div
                                                    v-else-if="zone === ZoneCodes.US"
                                                    class="ml-1 text-gray-dark"
                                                >
                                                    <span
                                                        >{{ customer.city }},
                                                        {{ customer.state }}</span
                                                    >
                                                </div>
                                                <em>
                                                    <SearchedText
                                                        class="ml-1 text-small"
                                                        classBaseText="text-gray-dark"
                                                        :searchedItem="customer"
                                                        searchedProperty="ocosCustomerNumber"
                                                    ></SearchedText>
                                                </em>
                                            </div>
                                        </li>
                                        <li
                                            v-if="modalReady && customersProcessed.length === 0"
                                            class="customers-list-contents-item"
                                        >
                                            <div class="customers-list-contents-item-details">
                                                <div>
                                                    {{
                                                        t('surgeonCustomerSelector_NoCustomerFound')
                                                    }}
                                                    {{ searchText }}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-card>
            </b-container>
        </template>
        <template #modal-footer>
            <b-container>
                <!-- Default Selection -->
                <b-row
                    v-if="
                        modalState.name !== ModalStateNames.VIEW_ALL_SURGEONS &&
                        modalState.name !== ModalStateNames.VIEW_ALL_CUSTOMERS
                    "
                >
                    <b-col>
                        <b-form-checkbox
                            :disabled="!enableDefaultSelectionCheckbox"
                            v-model="defaultSelectionSetting"
                            :value="DefaultSelectionOptions.SET_DEFAULT"
                            :unchecked-value="DefaultSelectionOptions.UNSET_DEFAULT"
                        >
                            {{ t('surgeonCustomerSelector_MakeMyDefault') }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <!-- View Dashboard -->
                <b-row
                    v-if="
                        modalState.name !== ModalStateNames.VIEW_ALL_SURGEONS &&
                        modalState.name !== ModalStateNames.VIEW_ALL_CUSTOMERS
                    "
                    class="mt-3"
                >
                    <b-col>
                        <b-button
                            block
                            variant="primary"
                            :disabled="!enableViewDashboardButton"
                            @click="onViewDashboard"
                        >
                            {{ t('surgeonCustomerSelector_ViewDashboard') }}
                        </b-button>
                    </b-col>
                </b-row>
                <!-- Cancel or Back -->
                <b-row class="mt-2 mb-3">
                    <b-col class="text-center">
                        <b-button
                            v-if="modalState.name === ModalStateNames.BASE"
                            variant="outline-none"
                            class="text-gray-dark"
                            @click="onCancel"
                        >
                            {{ t('cancel') }}
                        </b-button>
                        <b-button
                            v-else
                            variant="outline-none"
                            class="text-gray-dark"
                            @click="onBack"
                        >
                            {{ t('inventory_Back') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import {orderBy, debounce} from 'lodash';
import {mapActions, mapGetters} from 'vuex';
import {ZoneCodes} from '@/constants/zone';
import {setSearchResultProperties} from '@/utilities/search';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay.vue';
import SearchedText from '@/components/SearchedText.vue';

/**
 * The states of the modal
 */
const ModalStateNames = Object.freeze({
    BASE: 'BASE',
    CUSTOMER_SELECTED: 'CUSTOMER_SELECTED',
    SURGEON_SELECTED: 'SURGEON_SELECTED',
    CUSTOMER_AND_SURGEON_SELECTED: 'CUSTOMER_AND_SURGEON_SELECTED',
    VIEW_ALL_SURGEONS: 'VIEW_ALL_SURGEONS',
    VIEW_ALL_CUSTOMERS: 'VIEW_ALL_CUSTOMERS',
});

/**
 * The options for setting the default selection
 */
const DefaultSelectionOptions = Object.freeze({
    SET_DEFAULT: 'SET_DEFAULT',
    UNSET_DEFAULT: 'UNSET_DEFAULT',
});

/**
 * The debounce delay for the search input (in milliseconds)
 */
const SEARCH_INPUT_DEBOUNCE_DELAY = 500;

/**
 * Allows the user to select a customer with or without a surgeon.
 * The selection can be saved as the default.
 *
 * @emits cancel Emitted when the user cancels the selection
 * @emits customer-selected Emitted when the user selects a customer.
 *   At this time, a surgeon may or may not be selected. Both the
 *   selected customer and surgeon (if any) are sent with the event.
 */
export default {
    name: 'SurgeonCustomerSelectorModal',
    components: {SpinnerOverlay, SearchedText},
    props: {
        /* The zone the modal is configured for */
        zone: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            ZoneCodes,
            ModalStateNames,
            DefaultSelectionOptions,
            modalReady: false,
            modalStateHistory: [], // [Object {name, searchText}]
            searchText: '',
            customersProcessed: [],
            surgeonsProcessed: [],
            selectedCustomer: null,
            selectedSurgeon: null,
            defaultSelectionSetting: DefaultSelectionOptions.UNSET_DEFAULT,
        };
    },
    computed: {
        ...mapGetters('user', ['myCustomers', 'defaultCustomerId', 'defaultDoctorId']),
        ...mapGetters({
            mySurgeons: 'user/myDoctors',
        }),
        modalState() {
            // There should always be at least one state in the history
            return this.modalStateHistory[0];
        },
        headerText() {
            let headerText = '';
            if (this.modalState.name === ModalStateNames.VIEW_ALL_SURGEONS) {
                headerText = this.t('surgeonCustomerSelector_SelectSurgeon');
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS) {
                headerText = this.t('surgeonCustomerSelector_SelectCustomer');
            } else if (
                this.modalState.name === ModalStateNames.CUSTOMER_SELECTED ||
                this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED
            ) {
                headerText = this.t('surgeonCustomerSelector_Selected');
            } else {
                headerText = this.t('select');
            }
            return headerText;
        },
        searchPlaceholderText() {
            let placeholderText = '';
            if (this.modalState.name === ModalStateNames.BASE) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_1');
            } else if (this.modalState.name === ModalStateNames.SURGEON_SELECTED) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_2');
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_SURGEONS) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_3');
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_4');
            }
            return placeholderText;
        },
        surgeonsListClass() {
            let surgeonsListClass = '';
            if (this.modalState.name === ModalStateNames.VIEW_ALL_SURGEONS) {
                surgeonsListClass = 'surgeons-list-contents-long';
            } else {
                surgeonsListClass = 'surgeons-list-contents-short';
            }
            return surgeonsListClass;
        },
        customersListClass() {
            let customersListClass = '';
            if (this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS) {
                customersListClass = 'customers-list-contents-long';
            } else {
                customersListClass = 'customers-list-contents-short';
            }
            return customersListClass;
        },
        enableDefaultSelectionCheckbox() {
            let enable = false;
            if (
                (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED &&
                    this.selectedCustomer) ||
                (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED &&
                    this.selectedSurgeon &&
                    this.selectedCustomer)
            ) {
                enable = true;
            }
            return enable;
        },
        enableViewDashboardButton() {
            let enable = false;
            if (
                (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED &&
                    this.selectedCustomer) ||
                (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED &&
                    this.selectedSurgeon &&
                    this.selectedCustomer)
            ) {
                enable = true;
            }
            return enable;
        },
    },
    watch: {
        searchText(value) {
            this.onDebouncedSearchTextChange(value);
        },
        async modalState(state) {
            switch (state.name) {
                case ModalStateNames.BASE:
                    this.searchText = state.searchText;
                    this.clearSelectedSurgeon();
                    this.clearSelectedCustomer();
                    this.clearDefaultSelectionSetting();
                    await this.fetchSearchResults(this.searchText, null);
                    break;
                case ModalStateNames.SURGEON_SELECTED:
                    this.searchText = state.searchText;
                    this.clearSelectedCustomer();
                    this.clearDefaultSelectionSetting();
                    await this.fetchSearchResults(this.searchText, this.selectedSurgeon.doctorId);
                    break;
                case ModalStateNames.CUSTOMER_SELECTED:
                    this.clearSelectedSurgeon();
                    this.getDefaultSelectionSetting();
                    break;
                case ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED:
                    this.getDefaultSelectionSetting();
                    break;
                case ModalStateNames.VIEW_ALL_SURGEONS:
                case ModalStateNames.VIEW_ALL_CUSTOMERS:
                    // Keep the same search text from the previous search
                    await this.fetchSearchResults(this.searchText);
                    break;
                default:
                    break;
            }
        },
    },
    created() {
        this.onDebouncedSearchTextChange = debounce(
            this.onDebouncedSearchTextChange,
            SEARCH_INPUT_DEBOUNCE_DELAY
        );
    },
    async mounted() {
        this.modalStateHistory.unshift({
            name: ModalStateNames.BASE,
            searchText: '',
        });
        this.getDefaultSelectionSetting();
        await this.fetchSearchResults('', null);
    },
    methods: {
        ...mapActions('user', [
            'fetchMyCustomers',
            'fetchMyDoctors',
            'setActiveCustomerId',
            'setActiveDoctorId',
            'setDefaultCustomerId',
            'setDefaultDoctorId',
        ]),
        /**
         * Go to the given modal state
         *
         * @param {String} stateName
         */
        goToModalState(stateName) {
            this.modalStateHistory.unshift({
                name: stateName,
            });
        },
        /**
         * Fetch customers and/or doctors(surgeons) according to the given
         * search text and doctor id. After the request is complete,
         * adjust the UI to set the focus on the search input and scroll
         * the lists to the top. Finally, signal that the modal is ready
         * for use.
         *
         * @param {String} searchText
         * @param {Number} doctorId
         */
        async fetchSearchResults(searchText, doctorId) {
            this.modalReady = false;
            if (doctorId) {
                doctorId = doctorId.toString();
                const customers = await this.fetchMyCustomers({
                    query: searchText,
                    setMyCustomers: false,
                    doctorId,
                });
                this.processCustomers(searchText, customers);
            } else {
                const [surgeons, customers] = await Promise.all([
                    this.fetchMyDoctors({query: searchText, setMyDoctors: false}),
                    this.fetchMyCustomers({query: searchText, setMyCustomers: false, doctorId}),
                ]);
                this.processSurgeons(searchText, surgeons);
                this.processCustomers(searchText, customers);
            }
            if (this.$refs.searchInput) {
                // Move cursor to the end of the search text
                this.$refs.searchInput.$el.setSelectionRange(
                    this.$refs.searchInput.$el.value.length,
                    this.$refs.searchInput.$el.value.length
                );
                this.$refs.searchInput.$el.focus();
            }
            this.scrollListsToTop();
            this.modalReady = true;
        },
        /**
         * Handle the debounced search text change
         *
         * @param {String} searchText
         */
        async onDebouncedSearchTextChange(searchText) {
            this.modalState.searchText = this.searchText;
            let doctorId = this.selectedSurgeon?.doctorId;
            await this.fetchSearchResults(searchText, doctorId);
        },
        /**
         * Set properties for the found search text and sort the surgeons
         *
         * @param {String} searchText
         */
        processSurgeons(searchText, surgeons) {
            this.surgeonsProcessed = setSearchResultProperties(searchText, surgeons, [
                'firstName',
                'lastName',
                'ocosDoctorId',
            ]);

            this.surgeonsProcessed = orderBy(this.surgeonsProcessed, [
                (surgeon) => surgeon?.lastName?.toLowerCase(),
                (surgeon) => surgeon?.firstName?.toLowerCase(),
            ]);
        },
        /**
         * Set properties for the found search text and sort the customers
         *
         * @param {String} searchText
         */
        processCustomers(searchText, customers) {
            this.customersProcessed = setSearchResultProperties(searchText, customers, [
                'name',
                'ocosCustomerNumber',
            ]);

            this.customersProcessed = orderBy(this.customersProcessed, [
                (customer) => customer?.name?.toLowerCase(),
            ]);
        },
        /**
         * Scroll both the customer and surgeon lists to the top
         */
        scrollListsToTop() {
            if (this.$refs.surgeonsListContents) {
                this.$refs.surgeonsListContents.scrollTop = 0;
            }
            if (this.$refs.customersListContents) {
                this.$refs.customersListContents.scrollTop = 0;
            }
        },
        /**
         * Handle navigation to the View All Surgeons state
         */
        onViewAllSurgeons() {
            this.goToModalState(ModalStateNames.VIEW_ALL_SURGEONS);
        },
        /**
         * Handle navigation to the View All Customers state
         */
        onViewAllCustomers() {
            this.goToModalState(ModalStateNames.VIEW_ALL_CUSTOMERS);
        },
        /**
         * Handle a surgeon selection by updating the customers list and
         * automatically selecting the customer if there is only one. If
         * there is more than one customer, allow the user to select one.
         *
         * @param {Object} surgeon
         */
        async onSurgeonSelected(surgeon) {
            this.selectedSurgeon = surgeon;
            await this.fetchSearchResults('', this.selectedSurgeon.doctorId);

            if (this.customersProcessed.length === 1) {
                this.selectedCustomer = this.customersProcessed[0];
                this.goToModalState(ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED);
            } else {
                this.goToModalState(ModalStateNames.SURGEON_SELECTED);
            }
        },
        /**
         * Handle a customer selection by navigating to the appropriate modal
         * state
         *
         * @param {Object} customer
         */
        onCustomerSelected(customer) {
            this.selectedCustomer = customer;
            if (
                this.modalState.name === ModalStateNames.BASE ||
                this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS
            ) {
                this.goToModalState(ModalStateNames.CUSTOMER_SELECTED);
            } else if (this.modalState.name === ModalStateNames.SURGEON_SELECTED) {
                this.goToModalState(ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED);
            }
        },
        /**
         * Clear the selected surgeon
         */
        clearSelectedSurgeon() {
            this.selectedSurgeon = null;
        },
        /**
         * Clear the selected customer
         */
        clearSelectedCustomer() {
            this.selectedCustomer = null;
        },
        /**
         * Clear the default selection setting
         */
        clearDefaultSelectionSetting() {
            this.defaultSelectionSetting = DefaultSelectionOptions.UNSET_DEFAULT;
        },
        /**
         * Get the default selection setting. The default selection checkbox
         * will be checked if the user selects a customer and/or surgeon
         * that is already the current default.
         */
        getDefaultSelectionSetting() {
            if (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED) {
                if (this.defaultCustomerId === this.selectedCustomer?.customerId) {
                    this.defaultSelectionSetting = DefaultSelectionOptions.SET_DEFAULT;
                } else {
                    this.defaultSelectionSetting = DefaultSelectionOptions.UNSET_DEFAULT;
                }
            } else if (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED) {
                if (
                    this.defaultCustomerId === this.selectedCustomer?.customerId &&
                    this.defaultDoctorId === this.selectedSurgeon?.doctorId
                ) {
                    this.defaultSelectionSetting = DefaultSelectionOptions.SET_DEFAULT;
                } else {
                    this.defaultSelectionSetting = DefaultSelectionOptions.UNSET_DEFAULT;
                }
            }
        },
        /**
         * Handle the modal cancellation
         */
        onCancel() {
            this.$emit('cancel');
        },
        /**
         * Handle the back navigation
         */
        onBack() {
            this.modalStateHistory.shift();
        },
        /** Handle final navigation to the dashboard. This includes setting the
         * active selection and the default selection (if any)
         */
        async onViewDashboard() {
            if (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED) {
                if (this.defaultSelectionSetting === DefaultSelectionOptions.SET_DEFAULT) {
                    await this.setDefaultDoctorId(null);
                    await this.setDefaultCustomerId(this.selectedCustomer?.customerId);
                } else if (this.defaultSelectionSetting === DefaultSelectionOptions.UNSET_DEFAULT) {
                    await this.setDefaultCustomerId(null);
                }
            } else if (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED) {
                if (this.defaultSelectionSetting === DefaultSelectionOptions.SET_DEFAULT) {
                    await this.setDefaultCustomerId(this.selectedCustomer?.customerId);
                    await this.setDefaultDoctorId(this.selectedSurgeon?.doctorId);
                } else if (this.defaultSelectionSetting === DefaultSelectionOptions.UNSET_DEFAULT) {
                    await this.setDefaultCustomerId(null);
                    await this.setDefaultDoctorId(null);
                }
            }

            await this.setActiveCustomerId(this.selectedCustomer?.customerId);
            await this.setActiveDoctorId(this.selectedSurgeon?.doctorId);

            this.$emit('customer-selected', {
                selectedCustomer: this.selectedCustomer,
                selectedSurgeon: this.selectedSurgeon,
            });

            this.$router.push({name: 'Dashboard'});
        },
    },
};
</script>
